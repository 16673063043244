import {computed} from 'vue';
import { i18n } from './i18n';
const { t } = i18n.global;

const menuItems = computed(() => [{
      slug: 'reports',
      path: '/reports',
      name: 'Reports',
      label: t('taxhub.pages.menuItems.reports.linkLabel'),
      children: [
          {
              path: '/reports/overview',
              name: 'ListReports',
              label: t('taxhub.pages.menuItems.reports.children.overview.linkLabel')
          }
      ]
  }]);

export {
  menuItems
}