import { defineAsyncComponent } from "vue";
// Settings
const LoadingPage = () => import(/* webpackChunkName: "LoadingPage" */ '@dutypay/settings-pages/src/components/LoadingPage');

const AccountSettings = () => import(/* webpackChunkName: "AccountSettings" */ "@dutypay/settings-pages/src/pages/AccountSettings");

const AccountInformation = defineAsyncComponent({
    loader: () => import( /* webpackChunkName: "AccountInformation" */ '@dutypay/settings-pages/src/pages/AccountSettings/AccountInformation'),
    loadingComponent: LoadingPage
});
const SecuritySettings = defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "SecuritySettings" */ '@dutypay/settings-pages/src/pages/AccountSettings/SecuritySettings'),
    loadingComponent: LoadingPage
});

const CompanySettings = () => import(/* webpackChunkName: "CompanySettings" */ "@dutypay/settings-pages/src/pages/CompanySettings");
const IntegrationSettings = defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "IntegrationSettings" */ '@dutypay/settings-pages/src/pages/CompanySettings/IntegrationSettings'),
    loadingComponent: LoadingPage
});
const CompanyInformation = defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "CompanyInformation" */ '@dutypay/settings-pages/src/pages/CompanySettings/CompanyInformation'),
    loadingComponent: LoadingPage
});

const DeveloperSettings = () => import(/* webpackChunkName: "DeveloperSettings" */ "@dutypay/settings-pages/src/pages/DeveloperSettings");
const TranslationReport = defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "TranslationReport" */ '@dutypay/settings-pages/src/pages/DeveloperSettings/TranslationReport'),
    loadingComponent: LoadingPage
});
const TranslationEmailReportOverview = defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "TranslationEmailReportOverview" */ '@dutypay/settings-pages/src/pages/DeveloperSettings/TranslationEmailReportOverview'),
    loadingComponent: LoadingPage
});

const AdminSettings = () => import(/* webpackChunkName: "AdminSettings" */ "@dutypay/settings-pages/src/pages/AdminSettings");
const CompanyAdministration = defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "CompanyAdministration" */ '@dutypay/settings-pages/src/pages/AdminSettings/CompanyAdministration'),
    loadingComponent: LoadingPage
});
const UserAdministration = defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "UserAdministration" */ '@dutypay/settings-pages/src/pages/AdminSettings/UserAdministration'),
    loadingComponent: LoadingPage
});

// Pages
import {pages} from './pages.mjs';

export const routes = [
  // ----------------
  //  PAGES
  // ----------------

  ...pages,

  // ----------------
  //  SETTINGS
  // ----------------
  {
      path: '/account-settings',
      component: AccountSettings,
      children: [
          {
              path: 'account-information',
              component: AccountInformation
          },{
              path: 'security-settings',
              component: SecuritySettings
          }
      ]
  },
  {
      path: '/company-settings',
      component: CompanySettings,
      children: [{
          path: 'integrations',
          component: IntegrationSettings
      },{
          path: 'company-information',
          component: CompanyInformation
      }
      ]
  },
  {
      path: '/developer-settings',
      component: DeveloperSettings,
      children: [
          {
              path: 'translation-report',
              component: TranslationReport,
              beforeEnter: (to, from, next) => {
                  next();
              }
          },
          {
              path: 'translation-email-report-overview',
              component: TranslationEmailReportOverview,
              beforeEnter: (to, from, next) => {
                  next();
              }
          }
      ]
  },
  {
      path: '/admin-settings',
      component: AdminSettings,
      children: [
          {
              path: 'companies',
              component: CompanyAdministration,
          },
          {
              path: 'users',
              component: UserAdministration
          }
      ]
  }
];