const Reports = () => import(/* webpackChunkName: "Reports" */ './pages/Reports');
const ListReports = () => import(/* webpackChunkName: "ListReports" */ "./pages/Reports/ListReports");
const ReportDetails = () => import(/* webpackChunkName: "ReportDetails" */ "./pages/Reports/ReportDetails");

const pages = [
    {
      path: '/',
      redirect: '/reports'
    },
    {
      path: '/reports',
      name: 'Reports',
      slug: 'reports',
      component: Reports,
      children: [
        {
          path: '',
          redirect: '/reports/overview'
        },
        {
          path: 'overview',
          name: 'ListReports',
          slug: 'list-reports',
          component: ListReports
        },
        {
          path: 'details/:reportId',
          name: 'ReportDetails',
          slug: 'report-details',
          component: ReportDetails,
          props: true
        }
      ]
    }
];


export {
    pages
}