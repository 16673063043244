import { createWebHistory } from 'vue-router'

import { routes } from './routes.mjs';

export const routerConfig = {
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { left: 0, top: 0 }
        }
    },
    routes
}