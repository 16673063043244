<template>
  <el-config-provider :locale="elementLocale" :zIndex="3000">
    <DpApplicationContainer v-if="isAuth" :menuItems="menuItems">
      <ReminderSelectCompany v-if="!companyStore.hasSelectedCompany"/>
      <DpApplicationReminder v-if="hasReminder" :modelValue="reminders"/>
      <router-view/>
    </DpApplicationContainer>
  </el-config-provider>
</template>

<script setup>
import { ref, onBeforeMount, defineAsyncComponent, computed, watch } from "vue";
import { ElConfigProvider } from 'element-plus';
import { useAuthStore, useCompanyStore, useEnvStore } from "@dutypay/store-modules";
import elementLocales from '@dutypay/components-library/src/locales/element-plus'
import ReminderSelectCompany from '@dutypay/settings-pages/src/reminder/ReminderSelectCompany';
import { useI18n } from "vue-i18n";
import { menuItems } from './menuItems.js';
import { interceptor } from '@dutypay/utilities';
import { to } from "await-to-js";
import { isEmpty } from 'lodash';

const authStore = useAuthStore()
const envStore = useEnvStore()
const isAuth = computed(() => {
  return authStore.status === 'success'
})
const reminders = ref({});
const companyStore = useCompanyStore();
const DpApplicationReminder = defineAsyncComponent(() => import('../node_modules/@dutypay/settings-pages/src/reminder/index.js'))

const { locale } = useI18n({ useScope: 'global' })
const elementLocale = computed(() => {
  return elementLocales[locale.value];
})

async function fetchRemoteReminder() {
  if (isEmpty(companyStore.selectedCompany))
    return;
  let err, remoteFetch;
  [err, remoteFetch] = await to(
      interceptor.get(`${ envStore.apiUrls.dutypay }/api/v0/reminder-data/${ companyStore.selectedCompany.hs.company.id }`)
  );
  if (err) {
    return;
  }
  reminders.value = remoteFetch.data.result;
}
const hasReminder = computed(() => {
  return !isEmpty(reminders.value);
})
watch(locale, async() => {
  envStore.setGlobalLoading()
  await fetchRemoteReminder();
  envStore.releaseGlobalLoading()
})

async function initApp() {
  envStore.setGlobalLoading()
  await envStore.fetchHosts()
  await authStore.tokenAuth()
  await envStore.getDebug()
  await companyStore.initCompanyStore()
  await fetchRemoteReminder()
  envStore.releaseGlobalLoading()
}

onBeforeMount(async () => {
  await initApp();
})
</script>

<style lang="scss">
$--color-primary: #5392bc;
@import '~@dutypay/design-system/src/scss_legacy/index.scss'; // TODO: Legacy scss. Can be removed when the last relicts of element are gone.
</style>

<i18n>
{}
</i18n>
